import React from 'react'
// import { Link } from 'gatsby'

const Footer = () => {

  return (
    <>
    </>
  )
}

export default Footer