import React from 'react'
import { Link } from 'gatsby'

const Header = () => {

  return (
    <>
    <header className="header">

      <h1 className="header__logo">
        PB STUDIO
      </h1>

      <input type="checkbox" id="toggle" className="header__toggle" />

      <label htmlFor="toggle" className="header__toggle-label">
        <span></span>
      </label>

      <nav className="header__nav">
        <ul className="header__nav-list">
          <li className="header__nav-items">
            <Link to="/" className="header__nav-links">Home</Link>
          </li>
          <li className="header__nav-items">
            <Link to="/games" className="header__nav-links">Games</Link>
          </li>
          {/* <li className="header__nav-items">
            <Link to="/sports" className="header__nav-links">Sports</Link>
          </li> */}
          <li className="header__nav-items">
            <Link to="/crypto" className="header__nav-links">Crypto</Link>
          </li>
          <li className="header__nav-items">
            <Link to="/support" className="header__nav-links">Support</Link>
          </li>
        </ul>
      </nav>

    </header>
    </>
  )
}

export default Header